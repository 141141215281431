<template>
  <div clasS="page-wrapper">
    <terms-header />
    <generic-section height="auto">
      <div v-html="termsContent" />
    </generic-section>
  </div>
</template>

<script>
import GenericSection from "@/components/generic/GenericSection";
import TermsHeader from "@/pages/routes/TermsConditions/TermsHeader";
import { ref } from "vue";
export default {
  name: "index",
  components: {
    "generic-section": GenericSection,
    "terms-header": TermsHeader
  },
  setup() {
    const termsContent = ref("");
    fetch("https://storage.googleapis.com/static.cdwscan.com/terms_en.html").then(res => res.text()).then(response => {
      termsContent.value = response;
    })
    return {
      termsContent
    }
  }
}
</script>

<style scoped>
  :deep(p), :deep(ul), :deep(ol) {
    text-align: start;
  }
  :deep(p > a) {
    display: none;
  }

  .page-wrapper {
    width: 100vw;
    box-sizing: border-box;
  }
  @media screen and (max-width: 992px) {
    .page-wrapper {
      padding: 25px;
    }
  }
</style>