<template>
  <generic-section  bg-color="#FBFCFF" height="auto">
    <div style="height: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
      <h1 class="section-header">{{ $t("TERMS_CONDITIONS") }}</h1>
    </div>
  </generic-section>
</template>

<script>
import GenericSection from "@/components/generic/GenericSection";
export default {
  name: "TermsHeader",
  components: {
    "generic-section": GenericSection
  }
}
</script>

<style scoped>
  .section-header {
    font-weight: bold;
    font-size: 50px;
    line-height: 68px;
    text-align: center;

    color: #2E293D;
    margin: 150px 0 62px 0;
  }
  @media screen and (max-width: 992px) {
    .section-header {
      font-size: 2rem;
      margin: calc(var(--oh) * 10.5) 0 calc(var(--oh) * 5.5);
    }
  }
</style>